// Generated by Framer (114832d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["tDOQVUN3G", "c0aGDbzZG", "Szzkjup_z", "UABGiDiQ4", "S8hoYTTaw"];

const serializationHash = "framer-5JZaf"

const variantClassNames = {c0aGDbzZG: "framer-v-pvp3kx", S8hoYTTaw: "framer-v-f3ual0", Szzkjup_z: "framer-v-1ns7huh", tDOQVUN3G: "framer-v-15hfd6y", UABGiDiQ4: "framer-v-1kqx22r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Message-1": "tDOQVUN3G", "Message-2": "c0aGDbzZG", "Variant 3": "Szzkjup_z", "Variant 4": "UABGiDiQ4", "Variant 5": "S8hoYTTaw"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "tDOQVUN3G"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "tDOQVUN3G", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1cumprh = activeVariantCallback(async (...args) => {
await delay(() => setVariant("c0aGDbzZG"), 4000)
})

const onAppear1by3hof = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Szzkjup_z"), 2000)
})

const onAppear1i7n8yu = activeVariantCallback(async (...args) => {
await delay(() => setVariant("UABGiDiQ4"), 3000)
})

useOnVariantChange(baseVariant, {c0aGDbzZG: onAppear1by3hof, default: onAppear1cumprh, Szzkjup_z: onAppear1i7n8yu, UABGiDiQ4: undefined})

const sharedStyleClassNames = []

const isDisplayed = () => {
if (baseVariant === "S8hoYTTaw") return false
return true
}

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}>{isDisplayed() && (<Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(scopingClassNames, "framer-15hfd6y", className, classNames)} data-framer-name={"Message-1"} data-highlight layoutDependency={layoutDependency} layoutId={"tDOQVUN3G"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 36, borderBottomRightRadius: 36, borderTopLeftRadius: 36, borderTopRightRadius: 36, ...style}} {...addPropertyOverrides({c0aGDbzZG: {"data-framer-name": "Message-2", background: {alt: "", positionX: "center", positionY: "center"}}, Szzkjup_z: {"data-framer-name": "Variant 3", background: {alt: "", positionX: "center", positionY: "center"}}, UABGiDiQ4: {"data-framer-name": "Variant 4", "data-highlight": undefined, background: {alt: "", positionX: "center", positionY: "center"}}}, baseVariant, gestureVariant)}/></Transition>)}</Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5JZaf.framer-xkzn1m, .framer-5JZaf .framer-xkzn1m { display: block; }", ".framer-5JZaf.framer-15hfd6y { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 724px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 334px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5JZaf.framer-15hfd6y { gap: 0px; } .framer-5JZaf.framer-15hfd6y > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5JZaf.framer-15hfd6y > :first-child { margin-left: 0px; } .framer-5JZaf.framer-15hfd6y > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 724
 * @framerIntrinsicWidth 334
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"c0aGDbzZG":{"layout":["fixed","fixed"]},"Szzkjup_z":{"layout":["fixed","fixed"]},"UABGiDiQ4":{"layout":["fixed","fixed"]},"S8hoYTTaw":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbY7R4KL50: React.ComponentType<Props> = withCSS(Component, css, "framer-5JZaf") as typeof Component;
export default FramerbY7R4KL50;

FramerbY7R4KL50.displayName = "Screen Content - Reply Master";

FramerbY7R4KL50.defaultProps = {height: 724, width: 334};

addPropertyControls(FramerbY7R4KL50, {variant: {options: ["tDOQVUN3G", "c0aGDbzZG", "Szzkjup_z", "UABGiDiQ4", "S8hoYTTaw"], optionTitles: ["Message-1", "Message-2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerbY7R4KL50, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})